* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
}

a {
  color: inherit;
  text-decoration: none;
}

.swiper {
  --swiper-theme-color: #ffffff;
}